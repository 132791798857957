<template>
  <div class="grid"
       :class="['animated fadeIn']">
    <!-- search -->
    <el-row>
      <el-form inline
               label-suffix=":"
               label-position="left">
        <el-form-item label="流程名称">
          <el-input placeholder="请输入流程名称"
                    v-model="form.name"
                    size="mini"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="流程标识">
          <el-input placeholder="请输入流程标识"
                    v-model="form.key"
                    size="mini"
                    clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     size="mini"
                     icon="el-icon-search"
                     @click="$emit('search', form)">搜索</el-button>
          <el-button size="mini"
                     icon="el-icon-delete"
                     @click="$emit('reset')">清空</el-button>
          <el-button size="mini"
                     circle
                     icon="el-icon-s-data"
                     @click="$emit('mode', 'list')"></el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row :gutter="20"
            v-if="data.length > 0">
      <el-col :xs="24"
              :sm="12"
              :md="6"
              :lg="6"
              :xl="6"
              v-for="(item, index) in data"
              :key="index"
              class="grid-col">
        <el-card shadow="hover"
                 :body-style="{padding: '10px 20px'}"
                 @click.native="$emit('route', item)">
          <div class="grid-item"
               :title="item.name">
            <img :src="item.icon || require('../../../assets/img/icon-default.png')"
                 class="icon">
            <div class="info">
              <div class="name txt-cut">{{item.name}}：v{{item.version}}</div>
              <div class="key txt-cut">{{item.key}}</div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <template v-else>
      <avue-empty v-if="avueVersion('2.9.4')"
                  style="margin-top: 20%;"
                  desc="没有可发起的流程"></avue-empty>
      <el-empty v-else
                description="没有可发起的流程"
                style="margin-top: 20%;"></el-empty>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    form: Object
  }
}
</script>

<style lang="scss" scoped>
.txt-cut {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.grid {
  padding: 0 15px;
  height: calc(100vh - 165px);
  overflow: hidden scroll;
  &-col {
    margin-bottom: 16px;
  }
  .el-card {
    border-radius: 14px;
    &:hover {
      border-color: #388ef8;
    }
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .info {
      margin-left: 10px;
      flex: 1;
      word-break: break-all;

      .name {
        color: #388ef8;
        font-size: 15px;
        margin-bottom: 4px;
      }
      .key {
        font-size: 13px;
        color: #999;
      }
    }
  }
}
</style>
